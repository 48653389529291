/* Grid */

@custom-media --h-mobile-viewport (max-height: 414px) and (max-width: 1024px);
@custom-media --h-desktop-viewport (min-height: 720px) and (min-width: 1280px);
@custom-media --xs-viewport (min-width: 376px);
@custom-media --sm-viewport (min-width: 480px);
@custom-media --md-viewport (min-width: 860px);
@custom-media --lg-viewport (min-width: 1024px);
@custom-media --xlg-viewport (min-width: 1280px);
@custom-media --2xlg-viewport (min-width: 1920px);

.RS-section {
  width: 100%;
  padding: var(--RS-space-64) var(--RS-space-24) var(--RS-space-96);
  position: relative;


  @media (--md-viewport) {
    padding: var(--RS-space-64) var(--RS-space-24) var(--RS-space-128);
  }

  @media (--lg-viewport) {
    padding: var(--RS-space-96) var(--RS-space-32) var(--RS-space-128);
  }
}

.RS-section--inverted{
  background-color: var(--RS-black-color);
  color: var(--RS-white-color);
}

.RS-section-newsletter--standalone{
  padding: 12vh var(--RS-space-24) 4vh;

  @media (--lg-viewport) {
    padding: 12vh var(--RS-space-32) 12vh;
  }
}

.RS-section--popup{
  position: fixed;
  /* top: var(--RS-space-128); */
  /* left: calc(0px + var(--RS-space-24)); */
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 999;
  /* background: var(--RS-white-color); */
  backdrop-filter: blur(var(--RS-space-4));
  background: rgba(15,17,19,.5);
  /* padding: 0; */

  box-shadow: rgba(50, 50, 93, 0.05) 1px 51px 101px -21px, rgba(0, 0, 0, 0.08) 1px 31px 101px -31px;
  justify-content: center;
  align-items: center;
  display: none;
  cursor: pointer;
  opacity: 0;
  transition-timing-function: var(--RS-default-transition);
  transition-duration: var(--RS-anim-duration-md);
  transition-property: opacity;
  padding: var(--RS-space-32);

  @media (--md-viewport) {
    padding: var(--RS-space-32);
  }

  @media (--lg-viewport) {
    padding: var(--RS-space-32);
  }

  @media (--xlg-viewport) {
    padding: var(--RS-space-32);
  }
}

.RS-section--popup.RS-section--popup-displayed{
  display: flex;
}

.RS-section--popup.RS-section--popup-visible{
  opacity: 1;
}


.RS-section--popup .RS-container{
  max-width: var(--RS-col-6);
  overflow: hidden;
  border-radius: var(--RS-space-24);
  border: var(--RS-space-2) solid var(--RS-accent-color-primary);
  border-right-color: var(--RS-accent-color-secondary);
  border-bottom-color: var(--RS-accent-color-secondary);
  /* background: rgba(255,255,255,.95); */
  background: rgba(255,255,255,1);
  padding: var(--RS-space-32);
  cursor: default;
}

.RS-section--popup .RS-list__tile{
  gap: var(--RS-space-16);
}

.RS-section--popup .RS-list__tile:first-of-type{
  display: none;

  @media (--md-viewport) {
    display: flex;
  }
}

.RS-section--popup__close{
  position: absolute;
  top: var(--RS-space-32);
  right: var(--RS-space-32);
  cursor: pointer;
}


.RS-container {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: var(--RS-col-8);
  min-height: 100%;
  margin: 0 auto;
  height: 100%;
}

.RS-container.RS-container--narrow {
  max-width: var(--RS-col-4);
}

.RS-content{
  /* width: 100%; */
  flex-shrink: 0;
}

.RS-content--no-overflow{
  overflow: hidden;
}

.RS-content__figure{
  width: 100%;
  height: 100%;
  /* min-height: 20vh;
  border-radius: var(--RS-space-6);
  background-color: var(--RS-light-grey-color); */
}

.RS-section-about-experts .RS-content{
  margin-top: var(--RS-space-48-n);
  max-width: calc(var(--RS-space-192) + var(--RS-space-64));

  @media (--md-viewport) {
    max-width: calc(var(--RS-space-256) + var(--RS-space-4) + var(--RS-space-1));
    margin-top: var(--RS-space-32-n);
    margin-bottom: var(--RS-space-64-n);
  }
}

.RS-content__figure--about-experts{
  width: 80%;
  margin: var(--RS-space-16) auto 0 auto;


  
  @media (--md-viewport) {
    width: 100%;
    margin: unset;
  }

}

.RS-section-about-experts .RS-content .RS-header__description{
  margin-top: var(--RS-space-48-n);
  margin-left: 0;
  margin-right: 0;

  @media (--md-viewport) {
    transform: translateX(var(--RS-space-48));
    margin-top: var(--RS-space-64-n);
    width: calc(100% + var(--RS-space-24));
  }
}

.RS-section-about-experts .RS-article:last-of-type .RS-content .RS-header__description{

  @media (--md-viewport) {
    transform: translateX(var(--RS-space-64-n));
  }
}


.RS-section-about-experts .RS-article:last-of-type .RS-content .RS-header__description--highlighted > svg{
  fill: var(--RS-accent-color-secondary);
}


.RS-section-about-project .RS-list--tiles .RS-header__description--secondary{
  text-align: center;
}

.RS-section-about-user .RS-content{
  display: flex;
  flex-direction: column;
  /* gap: var(--RS-space-32); */
  /* opacity: 0; */

  @media (--md-viewport) {
    flex-direction: row;
  }
}

.RS-section-about-user .RS-content > div{
  @media (--md-viewport) {
    width: 40%;
  }
}

/* .RS-section-about-user .RS-content > div > div{
  margin-bottom: var(--RS-space-96);
} */

.RS-section-about-user .RS-content > ul{
  @media (--md-viewport) {
    width: 60%;
  }
 }

 .RS-section-about-process .RS-content{
  display: flex;
  flex-direction: column;

  @media (--md-viewport) {
    flex-direction: row;
  }
}

/* .RS-section-about-process .RS-content > div{
  @media (--md-viewport) {
    width: 40%;
  }
}

.RS-section-about-process .RS-content > ul{
  @media (--md-viewport) {
    width: 60%;
  }
 } */

 
 .RS-section-about-process .RS-header + .RS-content{
  margin-top: var(--RS-space-24);
 }

 /* .RS-section-about-product .RS-content{
  display: flex;
  gap: var(--RS-space-64);
  flex-direction: column;
  @media (--md-viewport) {
    flex-direction: row;
  }
 } */

 .RS-background-pointer-arrow > svg{
  position: absolute;
  top: 0%;
  width: calc(var(--RS-space-32) + var(--RS-space-6));
 }

 .RS-background-pointer-arrow > svg path{
  /* fill: var(--RS-graphite-color); */
  /* fill: var(--RS-graphite-color); */
  /* fill: var(--RS-dark-grey-color-wcag); */
  /* fill: var(--RS-brand-color); */
  fill: var(--RS-light-grey-color);
  /* opacity: .21; */
 }

 .RS-background-pointer-arrow:first-of-type > svg{
  left: calc(var(--RS-space-64-n) + var(--RS-space-8-n));
 }

 .RS-background-pointer-arrow:last-of-type > svg{
  right: calc(var(--RS-space-64-n) + var(--RS-space-8-n));
 }

 .RS-section-about-product .RS-background-pointer-arrow:first-of-type > svg{
  right: calc(var(--RS-space-64-n) + var(--RS-space-8-n));
  left: unset;
  transform: rotateY(180deg);
  
  @media (--md-viewport) {
    right: unset;
    left: calc(var(--RS-space-64-n) + var(--RS-space-8-n));
    transform: unset;
  }
 }

 /* .RS-section-about-facts .RS-background-pointer-arrow:last-of-type > svg{
  right: unset;
  left: calc(var(--RS-space-64-n) + var(--RS-space-8-n));
  transform: rotateY(180deg);
  
  @media (--md-viewport) {
    right: calc(var(--RS-space-64-n) + var(--RS-space-8-n));
    left: unset;
    transform: unset;
  }
 } */

 /* .RS-background-pointer-arrow.RS-background-pointer-arrow--bottom > svg{
  top: 140%;
  left: unset;
  right: 0;
  transform: rotateZ(230deg) rotateX(180deg);
 } */

 .RS-section-about-user{
  /* display: flex;
  flex-direction: column;
  gap: var(--RS-space-32); */
    margin-bottom: var(--RS-space-64);

  @media (--md-viewport) {
    margin-bottom: 0;
  }
}

.RS-section-about-process .RS-header__description{
  text-align: center;

  @media (--md-viewport) {
    text-align: left;
  }
}

.RS-hero-content__ola{
  position: absolute;
  top: calc(0px + var(--RS-space-128) + var(--RS-space-48));
  right: calc(0px - var(--RS-space-256) - var(--RS-space-16));
  z-index: -1;
  /* transform: translate(50%, -20%); */

  @media (--md-viewport) {
    top: calc(0px - var(--RS-space-128) + var(--RS-space-4));
    /* right: calc(0px - var(--RS-space-256) - var(--RS-space-16)); */
    right: -20%;
  }
}

/* .tolstoy-widget{
  transform: translateX(24px) translateY(20px);
} */